.block__people {
    background-size: cover;
    background-repeat: no-repeat;
    animation-duration: .4s;
    animation-timing-function: steps(5);
    animation-name: people-animation-out;

    &:hover {
        animation-name: people-animation-in;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
}

@keyframes people-animation-in {
  0% {
    background-position: left center;
  }
  100% {
    background-position: right center;
  }
}

@keyframes people-animation-out {
  0% {
    background-position: right center;
  }
  100% {
    background-position: left center;
  }
}