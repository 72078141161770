section {
    &.besoins {
        margin-bottom: 100px;

        &.responsive {
            height: 200px;
            overflow-x: scroll;
            overflow-y: hidden;

            .block-item,
            .block-item.selected.not-hover {
                width: 200px;

                h3 {
                    font-size: 1.2em;
                    margin-left: 10px;
                }

                span {
                    margin-top: -30px;
                    margin-left: -40px;
                    position: relative;
                }

                &::after {
                    width: 100%;
                    height: 150px;
                }

                &:hover,
                &.selected {
                    color: $white;

                    &:nth-child(1) {
                        span {
                            fill: $white;
                        }
                    }
        
                    &:nth-child(2) {
                        span {
                            .st0 {
                                fill: $white;
                            }
                        }
                    }
        
                    &:nth-child(3) {
                        span {
                            .st0,
                            .st1 {
                                stroke: $white;
                            }
                        }
                    }
        
                    &:nth-child(4) {
                        span {
                            .st1,
                            .st2,
                            .st3,
                            .st4,
                            .st5,
                            .st6,
                            .st7,
                            .st8,
                            .st9 {
                                stroke: $white;
                            }
                        }
                    }
                }
            }
        }

        .slice {
            position: absolute;
            width: 100px;
            background: $white;
            color: $warning;
            z-index: 2;
            transition: all .3s;
            left: 0;

            // &.isInMove {
            //     &::before {
            //         $height: 105px;
            //         $width: 74px;
            //         content: "";
            //         position: absolute;
            //         right: - $width;
            //         top: 0;
            //         display : inline-block;
            //         height : 0;
            //         width : 0;
            //         border-top : $height solid transparent;
            //         border-bottom : $height solid transparent;
            //         border-left : $width solid $white;
            //     }
            // }
            
            &::after {
                $height: 15px;
                content: "";
                position: absolute;
                width: 100%;
                height: $height;
                background: $warning;
                bottom: - $height;
                left: 0;
            }
        }

        .block-item,
        .block-item.selected.not-hover {
            h3 {
                font-size: 1.5em;
                font-family: "Bebas Regular";
            }

            color: $white;
            min-width: 12.5%;

            &:hover,
            &.selected {
                color: $warning;
                text-decoration: none;

                &::after {
                    opacity: 0;
                }

                &:nth-child(1) {
                    span {
                        fill: $warning;
                    }
                }
    
                &:nth-child(2) {
                    span {
                        .st0 {
                            fill: $warning;
                        }
                    }
                }
    
                &:nth-child(3) {
                    span {
                        .st0,
                        .st1 {
                            stroke: $warning;
                        }
                    }
                }
    
                &:nth-child(4) {
                    span {
                        .st1,
                        .st2,
                        .st3,
                        .st4,
                        .st5,
                        .st6,
                        .st7,
                        .st8,
                        .st9 {
                            stroke: $warning;
                        }
                    }
                }
            }

            span {
                content: "";
                position: absolute;
                width: 160px;
                height: 160px;
                top: 10%;
                left: 25%;

                path,
                ellipse,
                circle,
                line,
                rect {
                    transition: fill .4s ease;
                }
            }

            &:nth-child(1) {
                &::after {
                    display: none;
                }

                span {
                    top: 15%;
                    left: 10%;
                    fill: $white;
                }
            }

            &:nth-child(2) {
                span {
                    left: 10%;

                    .st0 {
                        fill: $white;
                    }
                }
            }

            &:nth-child(3) {
                span {
                    left: 10%;

                    .st0 {
                        fill: none;
                        stroke: $white;
                        stroke-width: 0.5;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-miterlimit: 10;
                        transition: all .4s;
                    }

                    .st1 {
                        @extend .st0;
                        stroke-dasharray: 2,2;
                    }
                }
            }

            &:nth-child(4) {
                span {
                    top: 25%;
                    left: 30%;

                    .st1,
                    .st2,
                    .st3,
                    .st4,
                    .st5,
                    .st6,
                    .st7,
                    .st8,
                    .st9 {
                        transition: all .4s;
                        stroke: $white;
                    }
                }
            }

            &::after {
                content: "";
                position: absolute;
                height: 100%;
                width: 110px;
                // background: url(../../images/fleche-besoins.png);
                background: url(../../images/ombreForBgWarning.png);
                top: 0;
                background-size: contain;
                background-repeat: no-repeat;
                left: 0;
                transition: all .1s;
            }
        }

        h2,
        .block-item {
            z-index: 3;
            transition: .3s all .1s;
        }
    }
}