#footer {
    .logo-footer {
        height: 100px;
    }

    .outro {
        font-size: .7em;
        width: 90%;
    }

    .ml {
        p {
            text-transform: uppercase;
        }
    }
}