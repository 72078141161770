.banner .nav li {
  @extend .nav-item;
}

.banner .nav a {
  @extend .nav-link;
}

.header {
  position: absolute;
  z-index: 6;
  width: 100%;

  .logo {
    width: auto;
    margin-right: 10px;
  }

  .separate {
      margin-left: -10px;
      top: 0;
      left: 1/3 * 100% ;
      width: 20px;
      height: 100%;
      background: #fff;
      position: absolute;
      z-index: -1;
  }

  .icons-right {
    position: fixed;
    z-index: 10;
    top: 90px;
    right: 0;
    cursor: pointer;
    transition: all .3s;
    
    span {
      width: 50px;
      height: 55px;
      transition: all .3s;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: .33em;
      background: $dark_transparent_bg;
      font-size: 2em;
      color: $white;
      margin-top: 3px;

      &.expend {
        width: 300px;
        justify-content: flex-start;
        margin-right: -250px;

        .content {
          font-size: 1.2rem;
          background: transparent;
          margin-top: 0;
        }

        &:hover {
          margin-right: 0;
        }
      }
    }
  }
  
  nav {
    background-color: $primary;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-transform: uppercase;
    overflow: scroll;

    .logo-link {
      margin-top: 10px;
    }
    
    ul {
      list-style-type: none;
      padding-right: 10%;
      transition: all 0.3s ease-in-out;
      align-self: flex-start;
      overflow: scroll;
      padding-top: 10%;
      align-self: center;
      
      li {
        transform: translateY(50px);
        opacity: 0;
        transition: all 0.2s ease-in-out;

        &.current-menu-item,
        &.current-menu-parent,
        &.current {
          a {
            color: $light_blue;

            &::before {
              opacity: 1;
              width: 20px;
            }
          }

          ul {
            padding-right: 0;
            margin-top: 0;
            padding-top: 0;
            align-self: auto;
            overflow: initial;
            height: auto;
            position: relative;

            &::before {
              opacity: 1;
              width: 20px;
            }

            li {
              &.current-menu-item,
              &.current-menu-parent,
              &.current {
                a {
                  color: $white;
                }
              }
            }
          }
        }
        
        a {
          display: block;
          text-decoration: none;
          text-align: right;
          color: $white;
          transition: all 0.2s ease-in-out;

          &::before {
            position: absolute;
            transition: all 0.3s ease;
            bottom: -1px;
            right: 0;
            display: block;
            height: 3px;
            width: 0%;
            content: "";
            background-color: $light_blue;
          }

          &:hover {
            color: $light_blue;
          }
        }

        ul {
          display: none;
          flex-direction: column;
          margin: 0;
          padding-bottom: 20px;
          // width: 460px;
          
          &::before {
            position: absolute;
            transition: all 0.3s ease;
            top: 0;
            right: 0;
            display: block;
            height: 3px;
            width: 0%;
            content: "";
            background-color: $light_blue;
          }

          li {
            a {
              text-transform: none;
              margin-bottom: 0;
              
              &::before {
                height: 0;
                width: 0%;
              }

              &:hover {
                color: $white
              }
            }
          }
        }
      }
    }
  }

  .toggle-btn {
    display: block;
    position: fixed;
    z-index: 10;
    top: 30px;
    right: 0;
    cursor: pointer;
    background: $dark_transparent_bg;
    transition: all .3s;
    padding: .4em;
    height: 53px;
    min-width: 53px;

    img {
      height: 40px;
      margin-right: 15px;
      margin-left: 5px;
      transition: all .3s;
    }
    
    .bar {
      width: 20px;
      height: 2px;
      margin: 7px auto;
      background-color: #fff;
      transition: all 0.3s ease-in-out;
      
      &:nth-child(2),
      &:nth-child(4) {
        width: 30px;
      }
      
      &:nth-child(3) {
        width: 40px;
      }
    }
  }
  
  #toggle:checked {
    &~nav {
      opacity: 1;
      visibility: visible;
  
      ul {        
        li {
          transform: translateY(0px);
          opacity: 1;
  
          @for $i from 1 through 6 {
            $t: $i * 0.1;
            &:nth-child(#{$i}) {
              transition: all 0.3s cubic-bezier(.6,0,.8,1.5) #{$t}s; 
            }
          }
        }
      }
    }
    
    &+label.toggle-btn {
      background: $white;

      img {
        transform: translateX(50px);
        display: none;
      }

      .bar {
        background-color: $primary;

        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(5) {
          opacity: 0;
        }
  
        &:nth-child(2) {
          transform: translateY(10px) rotate(45deg);
        }
  
        &:nth-child(4) {
          transform: translateY(-8px) rotate(-45deg);
        }
      }
    }
  
    &~.icons-right {
      span {
        background: $white;
        color: $dark_transparent_bg;
    
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          background: $light_blue;
        }
      }
    }
  }
}

.header-team {
  .content {
    padding-top: 50px;
  }
}