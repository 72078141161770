section {
    &.video {
        display: none;
    }

    &.besoins {
        text-transform: uppercase;
    }

    &.secteurs {
        h2 {
            &::before {
                content: "";
                background: $primary;
                width: 250px;
                height: 15px;
                position: absolute;
                margin-top: -63px;
            }
        }

        .block-title {
            position: absolute;
            bottom: 0;
            text-transform: uppercase;
            background: rgba($color: $white, $alpha: .7);

            .title {
                font-family: "Bebas Bold";
            }
        }

        .title-hover {
            text-transform: uppercase;
            font-family: "Bebas Bold";
            
            &::after {
                position: absolute;
                content: "+";
                font-size: 2em;
                top: 60%;
                left: 46%;
            }
        }
    }

    &.example-realisations {
        margin-bottom: 100px;
    }

    &.realisations {
        .card-realisation {
            @extend .mb-4;
        }
    }

    &.groupe {
        .logo {
            width: 100%;
            height: auto;
            z-index: 999;
        }

        .container {
            max-width: 1300px;
        }

        .entities {
            h1 {
                &::before {
                    content: "";
                    background: $primary;
                    width: 250px;
                    height: 15px;
                    position: absolute;
                    margin-top: -63px;
                }
            }

            .item {
                h2 {
                    color: $primary;
                    font-family: "Swiss";
                    font-size: 1.8em;
                }

                h3 {
                    background-color: $primary;
                    font-size: 1.5em;
                    width: 100%;
                }

                &:nth-child(2) {
                    h2 {
                        color: $info;
                    }
    
                    h3 {
                        background-color: $info;
                    }
                }

                &:nth-child(3) {
                    h2 {
                        color: $light_blue;
                    }
    
                    h3 {
                        background-color: $light_blue;
                    }
                }
            }
        }

        .chiffres {
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;

            .container {
                position: relative;
                
                &::before {
                    content: "";
                    background: $warning;
                    width: 250px;
                    height: 15px;
                    position: absolute;
                    top: -15px;
                }
            }

            .item {
                h2 {
                    font-size: 6em;
                }

                p {
                    text-transform: uppercase;
                }

                $border-top: 3px solid white;
                $width: 33%;
                $height: 1px;
                $position: absolute;
                
                &::before {
                    content: '';
                    border-top: $border-top;
                    width: $width;
                    height: $height;
                    position: $position;
                    top: 0;
                }

                &::after {
                    content: '';
                    border-top: $border-top;
                    width: $width;
                    height: $height;
                    position: $position;
                    bottom: 0;
                }
            }
        }

        .methodo {
            h2 {
                &::before {
                    content: "";
                    background: $primary;
                    width: 250px;
                    height: 15px;
                    position: absolute;
                    margin-top: -63px;
                }
            }

            .item {
                margin: 60px 0;

                .color {
                    z-index: 1;
                }

                .border {
                    z-index: 2;
                    border-width: 2px !important;

                    .h1 {
                        font-size: 7em;
                    }

                    h2 {
                        font-size: 3em;
                    }
                }

                .border {
                    width: 100%;
                }
            }
        }
    }

    &.categories {
        h2 {
            position: relative;
            font-family: "Swiss Condensed";
            
            &::before {
                content: "";
                width: 150px;
                height: 10px;
                background-color: $secondary;
                position: absolute;
                top: -20px;
            }
        }
    }
}
