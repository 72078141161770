/** Search form */
.search-form {
  @extend .form-inline;
}

.search-form label {
  @extend .form-group;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.contact {
  background: #{$primary}e7;
  color: $white;

  textarea {
    height: 100%;
  }

  .checkbox {
    font-size: 0.8rem;
  }

  .submit__button__ajax p {
    display: flex;
    flex-direction: row-reverse;
  }
}

.contact__modal {
  .typo__popin {
    font-size: 20px;
  }  
  
  .modal-header {
    border: none;
  }
}