/** Import Bootstrap functions */
@import "~bootstrap/scss/functions";
@import "~slick-carousel/slick/slick-theme";

$dark: #010101;
$grey: #bdbdbd;
$light: #ebeef1;

$primary: #142231;
$secondary: #223951;
$info: #36557a;
$success: #2e4d70;
$warning: #e46d16;
$danger: #f00c58;

$body-color: $dark;

$light_blue: #a4cff8;
$dark_transparent_bg: rgba(20, 34, 49, 0.7);

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  info: $info,
  warning: $warning,
  danger: $danger,
  dark: $dark,
  grey: $grey,
  light: $light,
  success: $success
);

$font-family-sans-serif: "Swiss", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-base:       $font-family-sans-serif !default;

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;


// Default var slick theme

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;