.header {
    .logo {
        max-height: 100px;
        height: 120px;

        &.classic {
            margin-top: 10px;
        }
    }

    nav {
        .logo-link {
            margin-left: 15px;
            margin-top: 0;
        }

        ul {
            padding-left: 0;

            li {
                padding-left: 0;

                a {
                    font-size: 1.3em;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.header-single {
    .single-thumbnail {
        .block-thumbnail {
            height: 550px;
            padding-top: 100px;
            overflow: hidden;

            img {
                width: 100% !important;
            }
        }
    }
    
    .block-single-content {
        position: absolute;
        top: 230px;
        width: 100%;
        height: 425px;
        padding-top: 100px;
    
        .content {
            background: #{$primary}e7;
            height: 200px;
        }
    }
}

.real-home-slider {
    margin-bottom: 300px;

    .slider-home-real-views {
        .slick-slide {
            .block__img {
                width: 100%;
                overflow: hidden;
                max-height: 300px;
                margin-top: 70px;
                vertical-align: middle;

                img {
                    // margin-top: 70px;
                    margin-bottom: 300px;
                    // height: 400px;
                    height: auto;
                    width: 100%;
                }
            }
        }
    }

    .block-slider-content {
        // margin-top: 120px;

        .content {
            height: 380px;
        }

        .slick-dots {
            bottom: 20px;
            left: 15px !important;
        }
    }
}

.secteurs {
    .direction-reveal {
        .direction-reveal__card {
            img {
                max-width: initial;
                margin-left: -25%;
            }
        }
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .header {
        nav {
            ul {
                align-self: initial;
            }
        }
    }

    .header-single {
        .block-single-content {
            margin-top: 220px;
        }
    }
    
    .content-post {
        margin-top: 250px;
    }

    .real-home-slider {
        .slider-home-real-views {
            .slick-slide {
                .block__img {
                    margin-top: 100px;
                }
            }
        }

        .block-slider-content {
            margin-top: 100px;

            .content {
                height: 300px;
            }
        }
    }

    .slider-actus-content {
        .slick-dots {
            bottom: 90px !important;
        }
    }

    .notre_approche {
        .img__border {
            position: initial;

            &::before {
                bottom: 44px;
            }
        }
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    h1,
    .h1 {
        font-size: 2.5rem;
    }

    .header {
        .block__logo {
            height: 200px;
        }

        .logo {
            min-height: 180px;
            max-height: 180px;
            margin-top: 0;

            &.white {
                margin-left: 30px;
            }
        }

        nav {
            .logo-link {
                margin-left: 72px;
            }
            
            ul {
                padding-left: 40px;
                margin-top: 0;
                align-self: auto;
                overflow: initial;
                height: auto;
                position: relative;

                li {
                    padding-left: 60px;

                    a {
                        font-size: 1.9em;
                    }
                }
            }
        }
    }
    
    .real-home-slider {
        .slider-home-real-views {
            .slick-slide {
                .block__img {
                    margin-top: 200px;

                    img {
                        margin-top: -15%;
                    }
                }
            }
        }

        .block-slider-content {
            margin-top: 200px;
        }
    }

    section {
        &.groupe {
            .chiffres {
                height: 350px;
            }

            .methodo {
                .item {
                    height: 250px;
                    margin: 120px 0 !important;

                    .color {
                        margin-top: -180px;
                    }
    
                    &.left {
                        .border {
                            margin-right: 4%;
                        }
                    }

                    &.right {
                        .border {
                            width: 96%;
                        }

                        .color {
                            margin-top: -140px;
                        }
                    }
                }
            }
        }

        &.portfolio {
            .real-slider {
                .block-real-slider-image {
                    overflow: hidden;
                    width: 100%;
                    height: 80vh;
            
                    img {
                        height: 100%;
                        width: initial;
                        margin: auto;
                    }
                }

                .slick-prev {
                    left: -25px;
                }
                
                .slick-next {
                    right: -25px;
                }
            
                .slick-dots {
                    display: block !important;
                }
            }
        }
    }

    .secteurs {
        .direction-reveal {
            .direction-reveal__card {
                img.direction-reaveal__img {
                    width: 100%;
                    margin-left: 0;
                }
            }
        }
    }

    .notre_approche {
        .img__border {
            &::before {
                bottom: 60px;
                height: 3px;
            }
        }
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .real-home-slider {
        margin-bottom: 250px;

        .slider-home-real-views { 
            margin-left: -1/3 * 100%;

            .slick-active {
                img {
                    width: 100% !important;
                }
            }
    
            .slick-slide {
                .block__img {
                    max-height: 700px;
                    margin: 0;

                    img {
                        margin: 0;
                    }
                }
            }
        }

        .block-slider-content {
            padding-left: 18vw;
            margin-top: 0;

            .content {
                height: 375px;
                width: 80% !important;
            }

            .slick-dots {
                bottom: 40px;
                left: 13em !important;
            }
        }
    }
    
    .header-single {
        margin-bottom: 50px;

        &.margin-big {
            margin-bottom: 250px;
        }

        .single-thumbnail {
            margin-left: 1/3 * 100%;

            .block-thumbnail {
                height: 600px;
                padding-top: 0;
            }
        }
        
        .block-single-content {
            padding-top: 0;
            margin-top: 0;
            top: 200px;
        }
    }

    .slider-actus-content {
        .slick-dots {
            bottom: 130px !important;
        }
    }

    .notre_approche {
        .img__border {
            &::before {
                bottom: 80px;
            }
        }
    }

    .content-post {
        margin-top: 0;

        .content {
            margin-left: -8%;
            margin-top: 50px;
            font-size: .8em;
        }
    }

    .contact__page {
        margin-left: -8%;
        margin-top: 50px;
    }
}

@media (min-width: 1168px) {
    .real-home-slider {
        margin-bottom: 200px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .real-home-slider {
        margin-bottom: 150px;

        .slider-home-real-views {
            .slick-slide {
                .block__img {
                    max-height: 650px;
                }
            }
        }
    }

    .slider-actus-content {
        .slick-dots {
            bottom: 100px !important;
        }
    }

    .header {
        nav {
            align-items: center;

            ul {
                padding-top: 0;
            }
        }
    }

    .notre_approche {
        .img__border {
            &::before {
                bottom: 95px;
            }
        }
    }
}

@media (min-width: 1390px) {  
    .real-home-slider {
        margin-bottom: 100px;

        .block-slider-content {        
            .content {
                width: 50% !important;
                height: 400px;

                .space-before-slider_content {
                    width: 40% !important;
                    flex: none;
                    max-width: inherit;
                }
            }

            .slick-dots {
                left: 17.5em !important;
            }
        }
    }

    .slider-actus-content {
        .slick-dots {
            bottom: 100px !important;
        }
    }
}