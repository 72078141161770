.card-realisation {
    .realisation-thumbnail {
        height: 250px;
        overflow: hidden;
    
        img {
            height: 100%;
            width: auto;
        }
    }
    
    .title {
        border-bottom: 3px solid $secondary;
    
        h3 {
            text-transform: uppercase;
            font-size: 1.3em;
            padding: 5px 0;
        }
    
        a {
            padding: 5px 0;
        }
    }
}

.card-actu {
    .bg-secondary {
        height: 400px;
        
        .actu-thumbnail {
            width: 100%;
            height: 240px;
            overflow: hidden;
        
            img {
                width: 100%;
                height: auto;
            }
        }
        
        .title {
            height: 100px;
    
            h3 {
                font-family: "Swiss Condensed";
                font-size: 1.6em;
                padding: 5px 0;
            }
        }
    }
}

.card-single {
    transition: all .3s;
    
    &:hover {
        opacity: .8;
    }
}