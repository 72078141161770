html {
  scroll-behavior: smooth;

  &.is-animating {
    .transition-fade {
      opacity: 0;
      transform: translateY(-40px);
    }
  }
  
  body {
    overflow-x: hidden;
  }
}

.transition-fade {
  transition: 0.4s;
  transform: translateY(0);
  opacity: 1;
}