@import "common/variables";

/** Import everything from autoload */
@import "./autoload/bootstrap";
@import "./autoload/slick";

/**
 * Import npm dependencies
 *
 * Prefix your imports with `~` to grab from node_modules/
 * @see https://github.com/webpack-contrib/sass-loader#imports
 */
// @import "~some-node-module";

/** Import theme styles */
@import "common/global";
@import "common/fonts";
@import "components/buttons";
@import "components/cards";
@import "components/forms";
@import "components/frise-besoins";
@import "components/trombi";
@import "components/example-realisations";
@import "components/list-cat";
@import "components/slider";
@import "components/direction-reveal";
@import "components/loading";
@import "components/modal";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";
@import "common/responsive";

// Import dependencies

@import "node_modules/direction-reveal/styles/direction-reveal.scss";