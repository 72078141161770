.real-home-slider {    
    .slick-dotted.slick-slider {
        margin-bottom: -30px;
    }
    
    .block-slider-content {
        position: absolute;
        width: 100%;
        height: 425px;
    
        .content {
            @extend .col-12;
            padding-bottom: 40px;
            background: rgba($color: $primary, $alpha: .9);

            .space-before-slider_content {
                @extend .col-3;
            }
        }
    }
}

.slider-actus-content {
    .content {
        h3 {
            height: 140px;
        }

        .read-more {
            margin-top: 70px;
            color: #fff;
            border: 1px solid #fff;
            background: transparent;
            padding: 15px 30px;
            transition: all .3s;

            &:hover {
                text-decoration: none;
                background: rgba(255, 255, 255, 0.3);
            }
        }
    }

    .slick-dots {
        left: 0 !important;
        bottom: 120px !important;
    }
}

.slick-dots {
    z-index: 5;
    width: auto;
    
    li {
        &:nth-child(1) {
            margin-left: 0;
        }

        button {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 2px solid #fff;

            &::before {
                content: none;
            }
        }

        &.slick-active {
            button {
                background: #fff;
            }
        }
    }
}

.portfolio {
    position: fixed;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all .3s;

    .bg-dark {
        height: 100%;
        position: relative;

        .close {
            color: $white;
            position: absolute;
            right: 20px;
            top: 20px;
        }
    }

    .real-slider {
        .block-real-slider-image {
            overflow: hidden;
            width: 100%;
    
            img {
                width: 100%;
            }
        }

        .slick-prev {
            left: 10px;
        }

        .slick-next {
            right: 10px;
        }

        .slick-arrow {
            z-index: 6;
        }
    
        .slick-dots {
            left: 38% !important;
            display: none !important;
        }
    }
}

.preview-galerie {
    .first-column {
        .w-100 {
            height: 50%;

            .rundiapo {
                width: 18%;
                line-height: 1;
                font-size: 2.2em;
            }

            .pictodiapo {
                width: 100px;
                height: auto;
            }

            .img-preview {
                min-width: 100%;
                min-height: 100%;
            }
        }
    }
}

.toggle-galerie {
    cursor: pointer;
}