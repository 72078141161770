#loading {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background-color: $primary;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loading__img {
        background-image: url(../../images/logomini.png);
        background-repeat: no-repeat;
        background-position: center;
        width: 100px;
        height: 100px;
        animation: bounce 3s ease infinite;
    }
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    
    10% {
        transform: translateY(-50px);
    }
    
    20% {
        transform: translateY(0);
    }
    
    30% {
        transform: translateY(-30px);
    }
    
    40% {
        transform: translateY(0);
    }
    
    50% {
        transform: translateY(-20px);
    }
    
    60% {
        transform: translateY(0);
    }
    
    70% {
        transform: translateY(-10px);
    }
    
    80% {
        transform: translateY(0);
    }
    
    87% {
        transform: translateY(-5px);
    }

    93% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-3px);
    }
}