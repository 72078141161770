.modal-xl {
  &.modal-100 {
    max-width: 1315px !important;
    width: 95%;

    .modal-header {
      .close {
        margin-left: 90px;
      }

      .logoBFM {
        width: 50px;
      }
    }

    video {
      width: 100%;
      height: auto;
    }
  }
}