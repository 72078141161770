section {
    &.example-realisations {
        h2 {
            &::before {
                content: "";
                width: 150px;
                height: 3px;
                background-color: $secondary;
                position: absolute;
                top: -20px;
            }
        }
    }
}