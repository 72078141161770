.sidebar__list__cat {
    .item {
        height: 80px;
        background-color: $secondary;
        color: $white;
        padding-right: 40px;
        transition: all .3s;
        cursor: pointer;
        will-change: background-color, color, box-shadow;

        p {
            font-family: "Swiss Condensed";
            text-transform: uppercase;
            font-size: 1.4em;
            line-height: 30px;
        }

        &:hover,
        &.selected {
            background-color: transparent;
            color: $secondary;
            box-shadow: 5px 0px 0px 0px $secondary;
        }
    }
}