.content-post {
    .content {
        background: #{$primary}e7;
    }
}

.map {
    height: 400px;
    overflow: hidden;
}

.notre_approche {
    position: relative;
    display: flex;

    .img__border {
        @extend .container;
        position: relative;
    
        &::before {
            content: '';
            width: 100%;
            height: 2px;
            background: #263952;
            position: absolute;
            left: 0;
            bottom: 50%;
        }
    }
}

.single__actu {
    .content {
        &::before {
            content: "";
            background: $primary;
            width: 250px;
            height: 20px;
            position: absolute;
            margin-top: -63px;
        }

        .cats {
            p {
                font-size: 1em;
            }
        }
    }

    h3, .h3 {
        @extend .h2;
        font-family: "Swiss Medium";
        font-size: 1.2rem;
    }
}

footer {

    a {
        color: $white;
        
        &:hover {
            color: $white;
            text-decoration: underline;
        }
    }

    .text__en-gras {
        font-weight: bold;
    }


    .mention__legal{
        font-size: 12px;
    }
}