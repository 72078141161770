@font-face {
    font-family: "BebasNeue";
    src: url(../../fonts/Bebas/BebasNeueLight.otf);
}

@font-face {
    font-family: "BebasNeue Book";
    src: url(../../fonts/Bebas/BebasNeueBook.otf);
}

@font-face {
    font-family: "BebasNeue Bold";
    src: url(../../fonts/Bebas/BebasNeue.ttf);
}

@font-face {
    font-family: "Swiss";
    src: url(../../fonts/Swiss/swiss-721-light-bt.ttf);
}

@font-face {
    font-family: "Swiss Condensed";
    src: url(../../fonts/Swiss/swiss-721-light-condensed-bt.ttf);
}

@font-face {
    font-family: "Swiss Medium";
    src: url(../../fonts/Swiss/swiss-721-medium-bt.ttf);
}

@font-face {
    font-family: "Swiss Bold";
    src: url(../../fonts/Swiss/swiss-721-bold-bt-70711.ttf);
}

@font-face {
    font-family: "Bebas Bold";
    src: url(../../fonts/Bebas/BebasNeueBold.otf);
}

@font-face {
    font-family: "Bebas Regular";
    src: url(../../fonts/Bebas/BebasNeueRegular.otf);
}

@font-face {
    font-family: "Bebas Book";
    src: url(../../fonts/Bebas/BebasNeueBook.otf);
}

h1,
.h1 {
    font-size: 1.5rem;
}

h2,
.h2 {
    text-transform: uppercase;
    font-family: "Swiss Bold";
    font-size: 1.5rem;
}

h1,
h3,
h4,
h5,
h6,
.h1,
.h3,
.h4,
.h5,
.h6,
.bebas {
    font-family: "BebasNeue";

    &.bold {
        font-family: "BebasNeue Bold";
    }

    &.book {
        font-family: "BebasNeue Book";
    }
}

.swiss-condensed {
    font-family: "Swiss Condensed";
}

.swiss-medium {
    font-family: "Swiss Medium";
}

.swiss-bold,
strong {
    font-family: "Swiss Bold";
}